import React from 'react';

export const LoginBanner: React.FC = () => {
    return (
        <div className="comments__anonymous">
            Войдите или зарегистрируйтесь, чтобы писать комментарии, задавать вопросы и участвовать в обсуждении.
            <div className="action">
                <a className="button login">Войти</a>
                <a className="button registration">Зарегистрироваться</a>
            </div>
        </div>
    );
};
