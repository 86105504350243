import { makeStyles } from '@material-ui/core';
import React from 'react';
import BarLoader from 'react-spinners/BarLoader';

interface IProps {
    text?: string;
}

const Spinner: React.FC<IProps> = ({ text }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <p>{text ?? 'Загрузка данных...'}</p>
            <BarLoader width="80%" color="#123abc"/>
        </div>
    );
};

const useStyles = makeStyles(
    {
        root: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '2rem',
            marginTop: '2rem',
            textAlign: 'center'
        }
    },
    { name: 'Spinner' }
);

export default Spinner;
